@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
}

.header-container {
  @apply flex fixed top-0 left-0 right-0 justify-between items-center py-4 px-8 text-black bg-white z-50;
}

#logo:hover {
  @apply cursor-pointer;
}

header ul {
  @apply flex;
}

header ul li a {
  @apply relative m-2 tracking-wider;
}

li a {
  @apply text-black duration-300;
}

li a:hover {
  @apply text-green-700 duration-300;
}

.footer-distributed {
  background-color: rgb(24, 72, 58);
  color: white;
}

.footer-distributed {
  @apply shadow-md box-border w-full text-left font-bold text-base px-12 py-14 mt-24;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  @apply align-top inline-block;
}

.footer-distributed .footer-left {
  @apply w-2/5;
}

.footer-distributed h3 {
  @apply text-white text-4xl;
}

.footer-distributed .footer-links {
  @apply text-white my-5 mb-3;
}

.footer-distributed .footer-links a {
  @apply inline-block h-7 font-normal;
}

.footer-distributed .footer-company-name {
  @apply text-white text-sm font-normal m-0;
}

.footer-distributed .footer-center {
  @apply w-4/12;
}

.footer-distributed .footer-center i {
  @apply bg-stone-800 text-white text-2xl w-9 h-9 rounded-full text-center leading-10 mx-4 my-2 align-middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

.footer-distributed .footer-right {
  width: 20%;
}

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 200px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 200px;
}

.gmap_iframe {
  height: 200px !important;
}

.mapouter2 {
  text-align: right;
  width: 100%;
  height: 400px;
  margin-top: 144px;
}

.gmap_canvas2 {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 400px;
}

.gmap_iframe2 {
  height: 400px !important;
}

.leaflet-container {
  width: 95vh;
  height: 70vh;
  justify-content: center;
  z-index: 1;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reference-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reference-img-on-map {
  height: 200px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.technologies-container-title {
  @apply text-4xl flex justify-center tracking-wider font-medium mt-16;
}

.technologies-container {
  @apply mb-12 mt-4 flex text-2xl justify-center gap-14;
}

.technologies-container i {
  @apply w-10 mr-2 text-red-600 text-center;
}

.technologies-container-element {
  @apply flex flex-row
}

.material-icons {
  @apply w-10 mr-2 text-red-600 text-center
}

.contacts-container {
  @apply w-1/3 mx-auto;
}

.contacts-header {
  @apply text-center mt-16 mb-8 text-3xl tracking-wider;
}

.contacts-main {
  @apply grid grid-rows-3 gap-2 border-l-2 border-green-700 pl-2;
}

.contacts-title {
  @apply text-green-700 tracking-widest;
}

.main-map-title {
  @apply text-4xl font-medium tracking-wider flex justify-center mb-10;
}

.reference-name-on-map {
  @apply text-2xl text-center text-green-800 my-2;
}

.custom-popup {
  @apply w-80 h-60;
}

.reference-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10em;
}

.company-card {
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
}

.company-city {
  font-size: 14px;
  margin-bottom: 20px;
}

.company-year {
  font-size: 14px;
  margin-bottom: 5px;
}

.company-location {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 4px;
}

.company-image {
  width: 300px;
  height: auto;
  margin: 10px auto;
}

.leaflet-marker-cluster-hover {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.reference-page-container {
  @apply flex mx-12 gap-8
}

.reference-page-left {
  @apply flex-1 flex flex-col justify-center
}

.reference-page-right {
  @apply flex-1 
}

.reference-page-image-container {
  @apply flex justify-center
}

.reference-page-company-name {
  @apply text-5xl tracking-wider mt-32 ml-12
}

.reference-page-company-location {
  @apply flex items-center gap-1 text-xl mb-8
}

.reference-page-company-description {
  @apply text-justify
}

.reference-page-map {
  width: 92%;
  height: 400px;
}

.reference-page-full-image {
  @apply hidden
}

.overlay {
  @apply fixed top-0 left-0 w-full h-full z-[51] bg-black flex flex-row justify-around bg-opacity-80
}

.overlay.hidden {
  display: none;
}

.overlay-image {
  max-width: 100%;
  max-height: 100%;
  margin: 5px;
}

.overlay-image.hidden {
  display: none;
}

.slide-img {
  @apply w-full h-full rounded-2xl bg-center bg-cover
}

.slide-img-home {
  @apply w-full h-full bg-center bg-cover duration-700 ease-in-out
}

.close-btn {
  @apply text-2xl text-white cursor-pointer h-8 mx-8 mt-4
}

.thumbnail {
  @apply w-32 h-32 m-4 mr-16 bg-center bg-cover
}

.thumbnail.selected {
  @apply border-white border-2
}

.thumbnail-container {
  @apply flex flex-col my-auto
}

.home-page-about-container {
  width: max;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-image: url("/public/images/vasfa-main7.png");
  background-size: cover;
  background-position: center;
  margin-top: 120px;
}

.home-page-about-title {
  @apply text-4xl font-medium tracking-wider
}

.home-page-about-text {
  @apply mt-16 text-white text-lg mx-12 text-center leading-8

}

.home-page-about-button {
  display: inline-block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 48px;
}

.home-page-about-button:hover {
  background-color: #0056b3;
}

.product-container {
  @apply flex flex-col justify-center text-center
}

.product-name {
  @apply text-xl mt-8 font-semibold
}

